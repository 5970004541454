import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useMount } from 'react-use';
import HLS from 'hls.js';

const VideoPlayer = forwardRef(
  ({ video, poster, autoPlay, onPlaybackState, isMuted = true }, ref) => {
    const [isHlsSupported, setIsHlsSupported] = useState();
    const hlsRef = useRef();
    const videoRef = useRef();

    useMount(() => {
      hlsRef.current = new HLS({
        maxMaxBufferLength: 10,
        autoStartLoad: false,
      });
      setIsHlsSupported(HLS.isSupported());
      if (HLS.isSupported()) {
        loadHlsVideo();
      }
    });

    useImperativeHandle(ref, () => ({
      onTogglePlay: handleTogglePlay,
    }));

    const loadHlsVideo = () => {
      if (!video?.streamingUrl) {
        return;
      }
      hlsRef.current.attachMedia(videoRef.current);
      hlsRef.current.on(HLS.Events.MEDIA_ATTACHED, function () {
        hlsRef.current.loadSource(video.streamingUrl);
      });
      hlsRef.current.on(HLS.Events.MANIFEST_PARSED, function () {
        if (autoPlay) {
          hlsRef.current.startLoad();
        }
      });
    };

    const handleTogglePlay = () => {
      if (videoRef.current.paused) {
        if (isHlsSupported) {
          hlsRef.current.startLoad();
        }
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    };

    const handleVideoEvent = e => {
      switch (e.type) {
        case 'play':
          if (onPlaybackState) {
            onPlaybackState('playing');
          }
          break;
        case 'ended':
          if (onPlaybackState) {
            onPlaybackState('stopped');
          }
          break;
        case 'pause':
          if (onPlaybackState) {
            onPlaybackState('paused');
          }
          break;
        default:
          break;
      }
    };

    return (
      <video
        poster={poster}
        autoPlay={autoPlay}
        playsInline
        muted={isMuted}
        loop
        ref={videoRef}
        onPlay={handleVideoEvent}
        onPause={handleVideoEvent}
        onEnded={handleVideoEvent}
      >
        {!isHlsSupported && (
          <>
            <source src={video?.streamingUrl} type="video/mp4" />
            <source src={video?.mp4Url} type="video/mp4" />
          </>
        )}
      </video>
    );
  },
);

export default VideoPlayer;
